<template>
    <div>
        <vs-row vs-justify="space-between">            
            <vs-col vs-lg="6" vs-xs="12" vs-sm="12" vs-type="flex" vs-justify="flex-start" vs-align="top">
                <vs-card fixedHeight>
                    <div slot="header" vs-justify="space-between">
                        <h3 class="card-title">{{estadio.estadioName}}</h3>
                    </div>
                    <vs-row>
                        <strong>{{$t('StadiumCode')}}:</strong>&nbsp;{{estadio.estadioCode}}
                    </vs-row>
                    <vs-row>
                        <strong>{{$t('Adress')}}:</strong>&nbsp;{{estadio.address}}
                    </vs-row>
                    <vs-row>
                        <strong>{{$t('City')}}:</strong>&nbsp;{{estadio.city}}
                    </vs-row>
                    <vs-row>
                        <strong>{{$t('Country')}}:</strong>&nbsp;{{RankingData[0].country_name_original}}
                    </vs-row>
                    <vs-row>
                        <strong>{{$t('Capacity')}}:</strong>&nbsp;{{estadio.capacity}}
                    </vs-row>
                    <vs-row>
                        {{estadio.capacityObs}}
                    </vs-row>
                    <vs-row vs-justify="flex-end">
                        <vs-tooltip text="Wikipedia">
                            <vs-button type="gradient" color="danger" icon="menu_book" target :href="estadio.wikiLink" :alt="Wiki"></vs-button>
                        </vs-tooltip>

                        <router-link :to='getRouteSurvey()'><vs-button color="primary" icon="turned_in_not"></vs-button></router-link>

                        <vs-button color="rgb(230,230,230)" color-text="rgb(50,50,50)" icon="settings"></vs-button>
                    </vs-row>
                    <vs-row>
                        <div>
                            <h4>Notas:</h4>
                            <vs-table :data="RankingData">
                                <template slot="thead">
                                    <vs-th vs-align="center">
                                        Geral
                                    </vs-th>
                                    <vs-th>
                                        Arq.
                                    </vs-th>
                                    <vs-th>
                                        Gra.
                                    </vs-th>
                                    <vs-th>
                                        Top.
                                    </vs-th>
                                    <vs-th>
                                        Ren.
                                    </vs-th>
                                    <vs-th>
                                        Man.
                                    </vs-th>
                                    <vs-th>
                                        Inf.
                                    </vs-th>
                                </template>
                                <template slot-scope="{data}">
                                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                        <vs-td vs-align="center">
                                            {{data[indextr].nota_geral_estadio}}
                                        </vs-td>
                                        <vs-td>
                                            {{data[indextr].nota_geral_arq}}
                                        </vs-td>
                                        <vs-td>
                                            {{data[indextr].last_gramado_nota_geral_geral}}
                                        </vs-td>
                                        <vs-td>
                                            {{data[indextr].last_topo_resumo_nivel_ponderado_sum}}
                                        </vs-td>
                                        <vs-td>
                                            {{data[indextr].last_gramado_nota_geral_dados_rendimento}}
                                        </vs-td>
                                        <vs-td>
                                            {{data[indextr].last_gramado_nota_geral_eqptos_manut}}
                                        </vs-td>
                                        <vs-td>
                                            {{data[indextr].last_gramado_nota_geral_infra}}
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </div>
                    </vs-row>
                    <vs-row>
                        <vs-col vs-lg="12">
                            <br />
                            <div>
                                <vs-tabs :color="colortab">
                                    <vs-tab label="Imagens" icon="photo_library" @click="colortab = '#8B0000'">
                                        <div class="con-example-images">
                                            <vs-images>
                                                <vs-image :key="index" :src="getImgUrl(index)" v-on:click="showImage(index)" v-for="(image, index) in 20" />
                                            </vs-images>
                                        </div>
                                        <div>
                                            <vs-pagination :total="maxPages" v-model="currentx"></vs-pagination>
                                        </div>
                                    </vs-tab>
                                    <vs-tab label="Documentos" icon="picture_as_pdf" @click="colortab = '#FFA500'">
                                        <div>
                                            <vs-table :data="docList">
                                                <template slot="header">
                                                    <h3>
                                                        Documentos para download
                                                    </h3>
                                                </template>
                                                <template slot="thead">
                                                    <vs-th>

                                                    </vs-th>
                                                    <vs-th>
                                                        Name
                                                    </vs-th>
                                                    <vs-th>
                                                        Tipo
                                                    </vs-th>
                                                </template>

                                                <template slot-scope="{data}">
                                                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                                        <vs-td>
                                                            <img :src="geticon(data[indextr].filetype)"
                                                                 alt="Pais"
                                                                 width="20" />
                                                        </vs-td>
                                                        <vs-td :data="data[indextr].file_caption">
                                                            <a :href="getDocUrl(indextr)" target="_blank">{{data[indextr].file_caption}}</a>
                                                        </vs-td>
                                                        <vs-td :data="data[indextr].filetype">
                                                            {{data[indextr].filetype}}
                                                        </vs-td>
                                                    </vs-tr>
                                                </template>
                                            </vs-table>
                                        </div>
                                    </vs-tab>
                                </vs-tabs>
                            </div>
                        </vs-col>
                    </vs-row>
                    <div slot="footer">
                        <!--
                <vs-row vs-justify="flex-end">
                    <vs-button type="gradient" color="danger" icon="menu_book" target :href="estadio.wikiLink" alt="Wiki"></vs-button>
                    <vs-button color="primary" icon="turned_in_not"></vs-button>
                    <vs-button color="rgb(230,230,230)" color-text="rgb(50,50,50)" icon="settings"></vs-button>
                </vs-row>
                    -->
                    </div>
                </vs-card>
            </vs-col>
            <vs-col vs-lg="6" vs-xs="12" vs-sm="12" vs-type="flex" vs-justify="flex-start" vs-align="top">
                <vs-card fixedHeight>
                    <div slot="header">
                        <h3 class="card-title">{{$t('Map')}}</h3>
                    </div>
                    <div slot="media">
                        <iframe :src="estadio.embed" width="100%" height="500px" style="padding:5px 5px 5px 5px;" frameBorder="0">
                        </iframe>
                    </div>
                </vs-card>
            </vs-col>

        </vs-row>
        <vs-row vs-justify="center">
        </vs-row>

        <vs-popup fullscreen title="Imagem" :active.sync="popupActivo">
            <p>
                <vs-image  :src="getImgUrlNormal(selectedImg)"  />
            </p>
        </vs-popup>

    </div>
</template>
<script>

  

// //////////////////////////////////////////
// Import Components Here
// //////////////////////////////////////////
    
    import { Stadiums } from "../../../services/Arena.js";
    const st = new Stadiums;
    import { Ranking } from "../../../services/Arena.js";
    const rk = new Ranking;

// //////////////////////////////////////////
// Export Here
// //////////////////////////////////////////
export default {
  name: "Principal",
        data: () => ({                        
            colortab: "green",
            title: "Det Stadium",
            urlThumb: "",
            urlNormal: "",
            urlDoc: "",
            estadio: {},
            resp: String,
            stats: {},            
            series: [], 
            docList: [], 
            imgList: [], 
            selectedImg:0,
            popupActivo: false,
            RankingData: [],
            currentx: 1,
            maxPages: 40,
            surveyId: 0,
            chartOptions: {
               labels: ['','','','','','','','','','','','','','','',''],
               //colors: ['#4fc3f7', '#2962FF', '#f62d51', '#ffbc34', '#212529'],
               responsive: [{
                    //breakpoint: 480,
                    options: {
                         chart: {
                      //        width: 400
                         },
                         legend: {
                              position: 'bottom'
                         }
                    }
               }]
          },
        }),        
        /*
        beforeRouteEnter(to, from, next) {                
            st.Get().then((resp) => {                                              
                next(vm => {
                    vm.stats = resp;                    
                    vm.setGraph();
                });
            }                                  
                , (err) => alert(err));                           
        },
        */
        methods: {            
            getRouteSurvey: function () {
                var s = "";
                //var id = this.RankingData[0].survey_id;
                //s = `/surveys/struct2/1/0`;
                //s = `/surveys/struct2/${this.surveyId}`;
                s = `/surveys/scoredet/${this.surveyId}`;                
                return s;
            },
            geticon(type) {
                var s = "";
                s = `/img/docs/${type}.png`;
                return s;
            },
            getImgTip(index) {
                var s = this.imgList[index].img_caption;
                return s;
            },

            getImgUrl(index) {
                var s = this.urlThumb + this.imgList[index].filename;
                return s;
            },

            getDocUrl(index) {
                var s = this.urlDoc + this.docList[index].filename;
                return s;
            },

            getImgUrlNormal(index) {
                var s = this.urlNormal + this.imgList[index].filename;
                return s;
            },

            showImage(index) {
                alert(index);
                this.selectedImg = index;
                this.popupActivo = true;
            }
        },
        beforeMount() {         
            
        },
        created() {           
            this.$vs.loading();            
            rk.Get(0, 0, this.$route.params.id,0).then((resp) => {
                this.RankingData = resp;             
                this.surveyId = this.RankingData[0].survey_id;
                this.$store.commit("SET_SelectedSurvey", this.RankingData[0].survey_id)

                st.GetImg(this.$route.params.id).then((resp3) => {
                    this.urlNormal = resp3.urlBaseNormal;
                    this.urlThumb = resp3.urlBaseThumb;
                    this.imgList = resp3.imgList;
                    
                    st.GetDocs(this.$route.params.id).then((resp2) => {
                        this.docList = resp2.docList;
                        this.urlDoc = resp2.urlBaseDoc;
                        for (var i = 0; i < this.$store.state.stadiumList.length; i++) {
                            if (this.$store.state.stadiumList[i].estadioId == this.$route.params.id) {
                                this.estadio = this.$store.state.stadiumList[i];
                            }
                        }
                        this.$vs.loading.close()
                    }, (err) => { this.$vs.loading.close(); alert(err) });            

                }, (err) => { this.$vs.loading.close(); alert(err) });            
                
            }, (err) => { this.$vs.loading.close(); alert(err) });            
        },
        computed:
        {
        },              
  components: {
    
  }
};
</script>
<style>
    .cardx {
        margin: 15px
    }
    
</style>